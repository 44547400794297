import { ComputedRef, computed } from "vue";
import { FieldInterface } from "~/entities/field";
import { IItem } from "~/entities/item";
import { useCollectionFields } from "./useCollectionFields";

export const useCollectionFieldsIncluding = (
  collectionName: string,
  item: ComputedRef<IItem | undefined>,
  fieldNames: string[],
) => {
  const { fields: collectionFields } = useCollectionFields(collectionName, item);

  const fields = computed<Record<string, FieldInterface>>(() => {
    const result: Record<string, FieldInterface> = {};

    for (const fieldName of fieldNames) {
      if (!(fieldName in collectionFields.value)) continue;
      result[fieldName] = collectionFields.value[fieldName];
    }
    return result;
  });

  return { fields };
};

