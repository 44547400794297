import { ComputedRef } from "vue";
import {
  FieldDataUpdateEmitPayload,
  FieldInterfaceEmitId,
} from "~/api/field-interfaces/emits";
import { FieldInterface } from "~/entities/field";
import { IItem } from "~/entities/item";
import { logger } from "~/service/logger/logger";

export const useFieldsEventListeners = (
  fields: ComputedRef<Record<string, FieldInterface>>,
  item: ComputedRef<IItem | undefined>,
) => {
  const fieldsEventListeners = {
    [FieldInterfaceEmitId.UPDATE_ITEM_FIELD_DATA]: (data: FieldDataUpdateEmitPayload) => {
      const fieldForChange = fields.value[data.fieldName];

      if (!fieldForChange) {
        logger().error(
          {
            collectionName: data.collectionName,
            fieldName: data.fieldName,
          },
          `unable to update field data. Field wasn't found.`,
        );

        return;
      }

      item.value?.setDataProperty(data.fieldName, data.updatedData);
      if (!fieldForChange.isDirty) fieldForChange.setDirty();
    },
  };

  return { fieldsEventListeners };
};

