import { useI18n } from "#i18n";
import { ComputedRef, computed } from "vue";
import cloneDeep from "lodash/cloneDeep";
import {
  useFieldsStore,
  Field,
  FieldInfoInterface,
  FieldInterface,
} from "~/entities/field";
import { IItem } from "~/entities/item";

export const useCollectionFields = (
  collectionName: string,
  item: ComputedRef<IItem | undefined>,
) => {
  const { locale } = useI18n();
  const fieldsStore = useFieldsStore();

  const formFields = computed<FieldInfoInterface[]>(() => {
    const sourceFields = fieldsStore.getAllByCollection(collectionName);
    return cloneDeep(sourceFields);
  });

  const fields = computed<Record<string, FieldInterface>>(() => {
    const result: Record<string, FieldInterface> = {};

    for (const fieldInfo of formFields.value) {
      result[fieldInfo.name] = new Field(
        fieldInfo,
        item.value?.getDataProperty(fieldInfo.name),
        locale.value,
      );
    }

    return result;
  });

  return { fieldsInfo: formFields, fields };
};

